
import { filter } from "~/composables/useFilter";

export default {
  inheritAttrs: false,

  props: {
    data: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    filter() {
      return filter;
    },

    chartData() {
      let d = [...this.data];

      const labels = [];
      const turnover = [];
      const grossProfit = [];
      const ebit = [];
      const netIncome = [];
      const balance = [];
      const equity = [];

      function parseData(s) {
        return parseInt(s || 0, 10);
      }

      d = d
          .filter((item) => item.ebit || item.balance || item.netIncome || item.equity || item.grossProfit || item.turnover)
          .sort((a, b) => {
            return a.year - b.year;
          });

      d.map((item) => {
        labels.push(item.year);
        turnover.push(parseData(item.turnover));
        grossProfit.push(parseData(item.grossProfit));
        ebit.push(parseData(item.ebit));
        netIncome.push(parseData(item.netIncome));
        balance.push(parseData(item.balance));
        equity.push(parseData(item.equity));
      });

      return {
        xaxis: {
          categories: labels,
        },

        series: [
          { name: this.$t("COMPANY_KEY_FIGURES_LABEL_TURNOVER"), data: turnover },
          { name: this.$t("COMPANY_KEY_FIGURES_LABEL_GROSS_PROFITS"), data: grossProfit },
          { name: this.$t("COMPANY_KEY_FIGURES_LABEL_EBIT"), data: ebit },
          { name: this.$t("COMPANY_KEY_FIGURES_LABEL_NET_INCOME"), data: netIncome },
          { name: this.$t("COMPANY_KEY_FIGURES_LABEL_BALANCE"), data: balance },
          { name: this.$t("COMPANY_KEY_FIGURES_LABEL_EQUITY"), data: equity },
        ],
      };
    },
  },
};
