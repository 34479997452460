
export default {
  inheritAttrs: false,

  props: {
    data: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      fieldsToDisplay: [
        { translation: this.$t("COMPANY_KEY_FIGURES_LABEL_TURNOVER"), key: "turnover" },
        { translation: this.$t("COMPANY_KEY_FIGURES_LABEL_GROSS_PROFITS"), key: "grossProfit" },
        { translation: this.$t("COMPANY_KEY_FIGURES_LABEL_EBIT"), key: "ebit" },
        { translation: this.$t("COMPANY_KEY_FIGURES_LABEL_NET_INCOME"), key: "netIncome" },
        { translation: this.$t("COMPANY_KEY_FIGURES_LABEL_BALANCE"), key: "balance" },
        { translation: this.$t("COMPANY_KEY_FIGURES_LABEL_EQUITY"), key: "equity" },
        { translation: this.$t("COMPANY_KEY_FIGURES_LABEL_SOLVENCY_RATIO"), key: "solvencyRatio" },
        { translation: this.$t("COMPANY_KEY_FIGURES_LABEL_NUMBER_OF_EMPLOYEES"), key: "employeeCount" },
      ],
    };
  },

  methods: {
    openAnnualReport(pdf) {
      window.open(pdf, "_blank");
    },
  },
};
