
import ResultsChart from "./components/ResultsChart";
import ResultsTable from "./components/ResultsTable";
// import BalanceTable from "./components/BalanceTable";
// import PortfolioTable from "./components/PortfolioTable";

import CompanyKeyFigures from "~/graphql/Company/CompanyKeyFigures.gql";

export default {
  apollo: {
    companyKeyFigures: {
      query: CompanyKeyFigures,

      update(data) {
        return data.companyByCvr;
      },

      error(error) {
        console.error(error);
      },

      variables() {
        return {
          cvrNumber: parseInt(this.$router.history.current.params.id, 10),
        };
      },
    },
  },

  components: {
    ResultsChart,
    ResultsTable,
    // BalanceTable,
    // PortfolioTable,
  },

  inheritAttrs: false,

  computed: {
    loading: function () {
      return this.$apollo.queries.companyKeyFigures.loading;
    },
  },

  methods: {
    filteredCompanyKeyFigures: function (keyFigures) {
      return keyFigures.filter((keyFigure) => {
        return keyFigure.balance || keyFigure.ebit || keyFigure.netIncome || keyFigure.equity || keyFigure.grossProfit || keyFigure.turnover;
      });
    },
  },
};
